$(".material-7days__tags_item.click-full").click(function() {
    $(".material-7days__tags_full-box").stop().fadeToggle(function() {
        adjustPosition($(this));
    });
});
function adjustPosition(element) {
    const box = element[0].getBoundingClientRect();
    const viewportWidth = $(window).width();
    const viewportHeight = $(window).height();
    if (box.right > viewportWidth) {
        element.css('right', viewportWidth - box.right + 'px');
    }
    if (box.bottom > viewportHeight) {
        element.css('top', viewportHeight - box.bottom + 'px');
    }
    if (box.left < 0) {
        element.css('left', '0px');
    }
}


$('.material-7days__slider').slick({
    arrows:false,
    slidesToShow:3,
    centerMode: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },

    ]
});





$('.childhood-7days__preview_slider').slick({
    arrows: false,
    slidesToShow: 3,
    centerMode: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay: true,
                arrows: false,
            }
        },

    ]
});
